import { Link } from "gatsby"
import React, { useState } from "react"
import Logo from "../../../assets/logo.png"
import Button from "../Button"
import { HamburgerIcon } from "../Icons"
import "./index.scss"
import Drawer from "../Drawer"

const Header = () => {
  const [isActive, setIsActive] = useState(false)
  return (
    <header className="header-container">
      <main className="header-main">
        <div className="logo">
          <img src={Logo} alt="" />
        </div>
        <div className="links desktop">
          <ul className="links-ul">
            {[
              { text: "About", link: "/about" },
              { text: "FAQ", link: "/faq" },
              { text: "Contact", link: "/contact" },
              { text: "Download App", isBtn: true },
            ].map(({ text, link, isBtn }) => (
              <li key={text} className="list-item" key={text}>
                {isBtn ? (
                  <Button>{text}</Button>
                ) : (
                  <Link to={link}>{text}</Link>
                )}
              </li>
            ))}
          </ul>
        </div>
        <div className="mobile-nav">
          <div onClick={() => setIsActive(!isActive)}>
            <HamburgerIcon />
          </div>
          <Drawer isActive={isActive} setIsActive={setIsActive} />
        </div>
      </main>
    </header>
  )
}

export default Header
