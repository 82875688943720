import classnames from "classnames"
import Img from "gatsby-image"
import React from "react"
import "./index.scss"

const Hero = ({ title, description, image, contentBoxes, isPrivacyPolicy }) => {
  return (
    <section
      className={classnames(
        "hero-section grid items-center container-padding",
        isPrivacyPolicy && "privacy-policy"
      )}
    >
      <div className="hero-image lg:ml-10">
        <Img fluid={image?.fluid} alt="" />
      </div>
      <div className="hero-content">
        <h1 className="text-5xl font-bold title">{title} </h1>
        <p className="description text-xl">{description}</p>
        {contentBoxes?.length > 0 && (
          <div className="content-boxes grid sm:grid-cols-2">
            {contentBoxes.map(
              ({ title, description: { description }, image }, index) => (
                <div className="content-box" key={title}>
                  <div className="icon">
                    <Img fluid={image?.fluid} alt="" />
                  </div>
                  <div className="content">
                    <h2 className="font-bold">
                      {title} {index + 1}
                    </h2>
                    <p>{description}</p>
                  </div>
                </div>
              )
            )}
          </div>
        )}
      </div>
    </section>
  )
}

export default Hero
