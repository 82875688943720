import classnames from "classnames"
import Img from "gatsby-image"
import React from "react"
import "./index.scss"

const Features = ({ title, description, contentBoxes }) => {
  return (
    <section className="app-feature-section section-margin container-padding">
      <div className="feature-content text-center">
        <h1 className="title text-5xl font-bold">{title}</h1>
        <p className="description text-lg">{description}</p>
      </div>
      <div className="feature-boxes justify-center grid sm:grid-cols-2 gap-5 md:flex">
        {contentBoxes.map(
          ({
            title,
            description: { description },
            image,
            isWhiteBackground,
          }) => (
            <div
              className={classnames(
                "feature-box text-center flex flex-col justify-center",
                isWhiteBackground && "white-background"
              )}
              key={title}
            >
              <div className="feature-logo">
                <Img fluid={image?.fluid} alt="" />
              </div>
              <div className="feature-box-content mt-5">
                <h2 className="title font-bold">{title}</h2>
                <p className="description mt-2">{description}</p>
              </div>
            </div>
          )
        )}
      </div>
    </section>
  )
}

export default Features
